import {
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React, { createElement, Fragment } from "react"
import rehypeReact from "rehype-react"
import { unified } from "unified"

const processor = unified().use(rehypeReact, {
  createElement,
  Fragment,
  components: {
    h1: props => (
      <Heading as="h1" size="xl">
        {props.children}
      </Heading>
    ),
    h2: props => (
      <Heading as="h2" size="lg">
        {props.children}
      </Heading>
    ),
    h3: props => (
      <Heading as="h3" size="md">
        {props.children}
      </Heading>
    ),
    h4: props => (
      <Heading as="h4" size="sm">
        {props.children}
      </Heading>
    ),
    h5: props => (
      <Heading as="h5" size="sm">
        {props.children}
      </Heading>
    ),
    h6: props => (
      <Heading as="h6" size="sm">
        {props.children}
      </Heading>
    ),
    a: props => {
      if (props.href.startsWith("/")) {
        return (
          <Link as={GatsbyLink} to={props.href}>
            {props.children}
          </Link>
        )
      }

      return <Link href={props.href}>{props.children}</Link>
    },
    p: props => <Text whiteSpace="pre-wrap">{props.children}</Text>,
    li: ListItem,
    ul: props => (
      <UnorderedList stylePosition="outside" pl={5}>
        {props.children}
      </UnorderedList>
    ),
    ol: props => (
      <OrderedList stylePosition="outside" pl={5}>
        {props.children}
      </OrderedList>
    ),
  },
})

const renderAst = (ast: any): JSX.Element => {
  return processor.stringify(ast) as unknown as JSX.Element
}

export default function ({ htmlAst }) {
  return <>{renderAst(htmlAst)}</>
}
