import { Box, Grid } from "@chakra-ui/layout"
import { Center, Square, Stack } from "@chakra-ui/react"
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage, getImage, getSrcSet } from "gatsby-plugin-image"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  Slide,
  Slider,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React, { useState } from "react"
import Lightbox from "react-spring-lightbox"

const margin = 5

export const Image = ({ image }) =>
  image.src && <GatsbyImage image={getImage(image.src)} alt={image.alt} />

export const Images = ({ images }) => {
  if (images) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [lightboxOpen, setLightboxOpen] = useState(false)

    const lightboxImages = images.map(image => ({
      srcSet: getSrcSet(image.src),
      alt: image.alt,
    }))

    return (
      <>
        <Stack>
          {images.map((image, i) => (
            <Box
              key={i}
              cursor="pointer"
              onClick={() => {
                setCurrentIndex(i)
                setLightboxOpen(true)
              }}
            >
              <Image image={image} />
            </Box>
          ))}
        </Stack>
        <CustomLightbox
          images={lightboxImages}
          open={lightboxOpen}
          setOpen={setLightboxOpen}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </>
    )
  }

  return null
}

export const Gallery = ({ images }) => {
  if (images) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [lightboxOpen, setLightboxOpen] = useState(false)

    const lightboxImages = images.map(image => ({
      srcSet: getSrcSet(image.src),
      alt: image.alt,
    }))

    const thumbs = images.map(
      (image, i) =>
        image.src && (
          <Center
            key={i}
            cursor="pointer"
            onClick={() => {
              setCurrentIndex(i)
              setLightboxOpen(true)
            }}
          >
            <GatsbyImage
              image={getImage(image.src.childImageSharp.thumb)}
              alt={image.alt}
            />
          </Center>
        )
    )

    return (
      <>
        {/* <Grid templateColumns="repeat(8, 1fr)" gap={15}> */}
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={15}>
          {thumbs}
        </Grid>
        <CustomLightbox
          images={lightboxImages}
          open={lightboxOpen}
          setOpen={setLightboxOpen}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </>
    )
  }

  return null
}

function CustomButton({ icon, ariaLabel, style = null, onClick = null }) {
  return (
    <Square
      size={10}
      borderRadius="md"
      bg="gray.900"
      _hover={{ bg: "gray.700" }}
      _active={{ bg: "gray.600" }}
      cursor="pointer"
      aria-label={ariaLabel}
      zIndex={999}
      style={style}
      onClick={onClick}
      textColor="white"
    >
      <FontAwesomeIcon icon={icon} />
    </Square>
  )
}

function CustomLightbox({
  images,
  open,
  setOpen,
  currentIndex,
  setCurrentIndex,
}) {
  const gotoPrevious = () =>
    currentIndex > 0 && setCurrentIndex(currentIndex - 1)
  const handleClose = () => setOpen(false)
  const gotoNext = () =>
    currentIndex + 1 < images.length && setCurrentIndex(currentIndex + 1)

  return (
    <>
      <Lightbox
        isOpen={open}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        onClose={handleClose}
        images={images}
        currentIndex={currentIndex}
        style={{
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          backgroundColor: "rgba(33, 33, 33, .6)",
        }}
        singleClickToZoom
        renderPrevButton={() =>
          images.length > 1 ? (
            <CustomButton
              style={{
                position: "absolute",
                left: margin,
              }}
              icon={faChevronLeft}
              ariaLabel="Previous Image"
              onClick={gotoPrevious}
            />
          ) : null
        }
        renderNextButton={() =>
          images.length > 1 ? (
            <CustomButton
              style={{
                position: "absolute",
                right: margin,
              }}
              icon={faChevronRight}
              ariaLabel="Next Image"
              onClick={gotoNext}
            />
          ) : null
        }
        renderHeader={() => (
          <CustomButton
            style={{
              position: "absolute",
              right: margin,
              top: margin,
            }}
            icon={faTimes}
            ariaLabel="Close"
            onClick={handleClose}
          />
        )}
      />
    </>
  )
}

export const Carousel = ({ images, withThumbnails }) => {
  if (images) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [lightboxOpen, setLightboxOpen] = useState(false)

    const lightboxImages = images.map(image => ({
      srcSet: getSrcSet(image.src),
      alt: image.alt,
    }))
    const thumbs = images.map(
      (image, i) =>
        image.src && (
          <Dot key={i} slide={i}>
            <Box width={100} height={100}>
              <GatsbyImage
                image={getImage(image.src.childImageSharp.thumb)}
                alt={image.alt}
              />
            </Box>
          </Dot>
        )
    )

    const maxWidth = Math.max.apply(
      Math,
      images.map(img => img.src.childImageSharp.original.width)
    )
    const minHeight = Math.min.apply(
      Math,
      images.map(img => img.src.childImageSharp.original.height)
    )

    return (
      <>
        <CarouselProvider
          naturalSlideWidth={maxWidth}
          naturalSlideHeight={minHeight}
          //   isIntrinsicHeight
          totalSlides={images.length}
        >
          <Box position="relative">
            <Slider>
              {images.map((image, i) => (
                <Slide
                  index={i}
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCurrentIndex(i)
                    setLightboxOpen(true)
                  }}
                >
                  <Image image={image} />
                </Slide>
              ))}
            </Slider>
            {images.length > 1 ? (
              <ButtonBack
                style={{
                  position: "absolute",
                  left: margin,
                  top: 0,
                  bottom: 0,
                }}
              >
                <CustomButton ariaLabel="Next Image" icon={faChevronLeft} />
              </ButtonBack>
            ) : null}
            {images.length > 1 ? (
              <ButtonNext
                style={{
                  position: "absolute",
                  right: margin,
                  top: 0,
                  bottom: 0,
                }}
              >
                <CustomButton
                  ariaLabel="Previous Image"
                  icon={faChevronRight}
                />
              </ButtonNext>
            ) : null}
          </Box>
          {withThumbnails ? (
            <Grid
              mt={15}
              templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
              gap={15}
            >
              {thumbs}
            </Grid>
          ) : null}
        </CarouselProvider>
        <CustomLightbox
          images={lightboxImages}
          open={lightboxOpen}
          setOpen={setLightboxOpen}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </>
    )
  }

  return null
}
