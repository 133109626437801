import { Box, Center, IconButton, Link, Stack, Text } from "@chakra-ui/react"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"

export function Vimeo({ html, thumbnail, title }) {
  const [showVideo, setShowVideo] = useState(false)
  return (
    <Box position="relative" maxWidth={600}>
      {showVideo ? (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <>
          <GatsbyImage image={getImage(thumbnail)} alt={title} />
          <Center
            position="absolute"
            top={{ base: 5, md: 20 }}
            bottom={{ base: 5, md: 20 }}
            left={{ base: 5, md: 20 }}
            right={{ base: 5, md: 20 }}
            textAlign="center"
          >
            <Stack>
              <Text>
                <IconButton
                  size="lg"
                  borderRadius="full"
                  icon={<FontAwesomeIcon icon={faPlay} />}
                  aria-label={`Video ${title} starten`}
                  onClick={() => setShowVideo(true)}
                />
              </Text>
              <Text
                fontSize={{ base: "xs", md: "lg" }}
                color="white"
                bg="rgba(33, 33, 33, .6)"
                borderRadius="md"
              >
                Nach Klick auf den Play-Button wird das Video von Vimeo geladen.
                Dabei können personenbezogene Daten wie die IP-Adresse an Vimeo
                übertragen werden. Details finden Sie in unserer{" "}
                <Link as={GatsbyLink} to="/datenschutzerklarung/">
                  Datenschutzerklärung
                </Link>
                .
              </Text>
            </Stack>
          </Center>
        </>
      )}
    </Box>
  )
}
