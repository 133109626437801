import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Teaser from "./teaser"

export function ServiceAreas() {
  const data = useStaticQuery(graphql`
    query ServiceAreasQuery {
      serviceAreas: allLeistungsbereicheYaml {
        nodes {
          title
          icon
          fields {
            slug
          }
          serviceNodes {
            fields {
              slug
            }
          }
          teaser {
            childImageSharp {
              gatsbyImageData(width: 300, height: 300)
            }
          }
        }
      }
    }
  `)

  data.serviceAreas.nodes.forEach(node => {
    if (node.serviceNodes.length == 1) {
      node.fields.slug = node.serviceNodes[0].fields.slug
    }
  })

  return <Teaser nodes={data.serviceAreas.nodes} />
}
