import { Grid, Heading, Link, Stack, Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/react"
import { faEnvelope, faPhone, faTag } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

interface Props {}

interface Data {
  staff: {
    categories: {
      title: string
      employees: {
        title: string
        avatar: {
          childImageSharp: {
            gatsbyImageData: unknown
          }
        }
        role: string
        email?: string
        phone?: string
      }[]
    }[]
  }
}

export function Staff(props: Props): JSX.Element {
  const data: Data = useStaticQuery(graphql`
    query StaffQuery {
      staff: mitarbeiterYaml(title: { eq: "Mitarbeiter" }) {
        categories {
          title
          employees {
            title
            avatar {
              childImageSharp {
                gatsbyImageData(width: 200, height: 200)
              }
            }
            role
            # email
            # phone
          }
        }
      }
    }
  `)

  return (
    <Stack spacing={7}>
      {data.staff.categories.map((category, i) => (
        <Box key={i}>
          <Heading as="h2" size="md" mb={3}>
            {category.title}
          </Heading>
          <Grid
            gap={5}
            templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
          >
            {category.employees.map((employee, i) => (
              <Box key={i}>
                <Stack direction="row">
                  <GatsbyImage
                    style={{ flexShrink: 0 }}
                    image={getImage(employee.avatar)}
                    alt={employee.title}
                  />
                  <Stack direction="column">
                    <Heading as="h3" size="sm">
                      {employee.title}
                    </Heading>
                    <Text>
                      <FontAwesomeIcon icon={faTag} />
                      &nbsp;{employee.role}
                    </Text>
                    {employee.email && (
                      <Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                        &nbsp;
                        <Link href={`mailto:${employee.email}`}>
                          {employee.email}
                        </Link>
                      </Text>
                    )}
                    {employee.phone && (
                      <Text>
                        <FontAwesomeIcon icon={faPhone} />
                        &nbsp;
                        <Link href={`tel:${employee.phone}`}>
                          {employee.phone}
                        </Link>
                      </Text>
                    )}
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Grid>
        </Box>
      ))}
    </Stack>
  )
}
