import { Heading, Stack } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import React from "react"
import Form from "../components/form"
import { Carousel, Gallery, Images } from "../components/images"
import Markdown from "../components/markdown"
import { Metadata } from "../components/metadata"
import { Seo } from "../components/seo"
import { ServiceAreas } from "../components/services"
import { Staff } from "../components/staff"
import { Vimeo } from "../components/video"

function BodyComponent({
  body,
  mdNodes,
  remoteNodes,
}: {
  body: any
  mdNodes: Array<any>
  remoteNodes: Array<any>
}) {
  switch (body.__typename) {
    case "BodyText":
      return (
        <Markdown
          htmlAst={
            mdNodes.find(
              node => node.childMarkdownRemark.rawMarkdownBody === body.markdown
            ).childMarkdownRemark.htmlAst
          }
        />
      )
    case "BodyImages":
      switch (body.variant) {
        case "gallery":
          return <Gallery images={body.images} />
        case "carousel":
          return <Carousel images={body.images} withThumbnails={false} />
        case "carousel-thumbnails":
          return <Carousel images={body.images} withThumbnails={true} />
        default:
          return <Images images={body.images} />
      }
    case "BodyMetadata":
      return <Metadata />
    case "BodyStaff":
      return <Staff />
    case "BodyServiceAreas":
      return <ServiceAreas />
    case "BodyVimeo":
      const remoteNode = remoteNodes.find(node => node.url === body.url)
      return (
        <Vimeo
          html={remoteNode.html}
          thumbnail={remoteNode.thumbnail}
          title={remoteNode.title}
        />
      )
    case "BodyForm":
      return (
        <Form
          fields={body.fields}
          sendTo={body.sendTo}
          subject={body.subject}
        />
      )
    default:
      return null
  }
}

// https://www.gatsbyjs.com/blog/fs-route-api/
export default function ({ data }) {
  const { page, menus } = data
  const { mdNodes, remoteNodes } = page.fields

  let heading = <Heading as="h1">{page.title}</Heading>

  if (page.serviceAreaNode) {
    const serviceArea = page.serviceAreaNode.childLeistungsbereicheYaml
    let servicePrefix = null

    if (serviceArea.title !== page.title) {
      servicePrefix = serviceArea.title + `\u00A0—\u00A0`
    }

    heading = (
      <Heading as="h1">
        <FontAwesomeIcon icon={["fas", serviceArea.icon]} />
        &nbsp;
        {servicePrefix}
        {page.title}
      </Heading>
    )
  } else {
    const menuEntry = data.menus.nodes
      .map(node => node.entries)
      .flat()
      .find(entry => entry.url === page.fields.slug)

    if (menuEntry) {
      heading = (
        <Heading as="h1">
          <FontAwesomeIcon icon={["fas", menuEntry.icon]} />
          &nbsp;{page.title}
        </Heading>
      )
    }
  }

  const currentSlug = useLocation().pathname

  return (
    <>
      <Seo title={page.title} description={page.summary} />
      <Stack spacing={5}>
        {currentSlug === "/" ? null : heading}
        {page.body.map((body, i) => (
          <BodyComponent
            body={body}
            mdNodes={mdNodes}
            remoteNodes={remoteNodes}
            key={i}
          />
        ))}
      </Stack>
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: pagesYaml(id: { eq: $id }) {
      title
      summary
      serviceAreaNode {
        childLeistungsbereicheYaml {
          title
          icon
        }
      }
      fields {
        slug
        mdNodes {
          childMarkdownRemark {
            htmlAst
            rawMarkdownBody
          }
        }
        remoteNodes {
          url
          html
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      body {
        __typename
        ... on BodyImages {
          variant
          images {
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
                thumb: gatsbyImageData(width: 200, height: 200)
                original {
                  width
                  height
                }
              }
            }
            alt
          }
        }
        ... on BodyText {
          variant
          markdown
        }
        ... on BodyForm {
          sendTo
          subject
          fields {
            name
            type
            required
          }
        }
        ... on BodyVimeo {
          url
        }
      }
    }
    menus: allMenusYaml {
      nodes {
        entries {
          url
          icon
        }
      }
    }
  }
`
