import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Stack,
  Text,
  Textarea,
  useColorMode,
  useToast,
} from "@chakra-ui/react"
import { faCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

export default function Form({ sendTo, subject, fields }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          captchaSiteKey
        }
      }
    }
  `)

  const { colorMode } = useColorMode()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const onSubmit = data => {
    setLoading(true)

    const formData = new FormData()
    // formData.append("sendFrom", site.siteMetadata.email)
    formData.append("sendTo", sendTo)
    formData.append("subject", subject)

    for (const [key, value] of Object.entries(data)) {
      if (value instanceof Object) {
        if (Object.keys(value).length == 1) {
          formData.append(key, value[0])
        } else {
          for (const [subindex, subvalue] of Object.entries(value)) {
            formData.append(`${key} ${subindex}`, subvalue)
          }
        }
      } else {
        formData.append(key, value)
      }
    }

    fetch(`/api/form`, {
      method: `POST`,
      body: formData,
    })
      .then(res => res.json())
      .then(body => {
        setLoading(false)
        toast({
          title: body.title,
          description: body.description,
          status: body.status,
          duration: 5000,
          isClosable: true,
        })
      })
  }

  const onVerifyCaptcha = token => {
    setValue("captchaToken", token)
  }

  const [consent, setConsent] = useState(false)

  return (
    <>
      <noscript>
        <Text as="mark">
          In ihrem Browser ist JavaScript deaktiviert. Um dieses Formular nutzen
          zu können, müssen Sie JavaScript aktivieren. Da hierbei alle Eingaben
          verloren gehen, sollten Sie dies vor dem Ausfüllen der Felder machen.
        </Text>
      </noscript>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Grid
            gap={5}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          >
            {fields.map((field, i) => (
              <FormControl
                isRequired={field.required === true}
                key={i}
                gridColumn={field.type === "textarea" ? "span 2" : ""}
              >
                <FormLabel>{field.name}</FormLabel>
                {field.type === "textarea" ? (
                  <Textarea
                    {...register(field.name, {
                      required: field.required === true,
                    })}
                  />
                ) : (
                  <Input
                    type={field.type}
                    pt={field.type === "file" ? 1 : 0}
                    {...register(field.name, {
                      required: field.required === true,
                    })}
                  />
                )}
                <FormHelperText>{field.help}</FormHelperText>
                <FormErrorMessage>
                  {errors[field.name] && errors[field.name].message}
                </FormErrorMessage>
              </FormControl>
            ))}
          </Grid>
          {consent ? (
            <>
              <HCaptcha
                sitekey={site.siteMetadata.captchaSiteKey}
                onVerify={onVerifyCaptcha}
                theme={colorMode}
                reCaptchaCompat={false}
              />
              <Button
                w={300}
                isLoading={loading}
                type="submit"
                leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
              >
                Senden
              </Button>
            </>
          ) : (
            <Stack>
              {/* <Heading as="p" size="md">
                Vor dem Absenden
              </Heading>
              <Text>
                Ich habe die{" "}
                <Link
                  as={GatsbyLink}
                  to="/datenschutzerklarung/"
                  target="_blank"
                >
                  Datenschutzerklärung
                </Link>{" "}
                zur Kenntnis genommen.
              </Text>
              <Heading as="p" size="sm">
                Nutzung Persönlicher Daten
              </Heading>
              <Text>
                Ich stimme zu, dass meine Angaben und Daten zur Beantwortung
                meiner Anfrage elektronisch erhoben und gespeichert werden.
                Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft
                per E-Mail die folgende Adresse widerrufen:{" "}
                <Link href={`mailto:${site.siteMetadata.email}`}>
                  {site.siteMetadata.email}
                </Link>
              </Text>
              <Heading as="p" size="sm">
                Spamschutz
              </Heading> */}
              <Text>
                Zur Vermeidung von Spam nutzen wir den Dienst hCaptcha. Hierbei
                wird ein Cookie auf Ihrem Gerät gespeichert, der die aktuelle
                Sitzung kennzeichnet. Dadurch ist es für hCaptcha mögliche, Ihre
                Eingabe korrekt zuzuordnen.
              </Text>
              <Button
                cursor="pointer"
                as="span"
                w={300}
                onClick={() => setConsent(true)}
                leftIcon={<FontAwesomeIcon icon={faCheck} />}
              >
                Zustimmen
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    </>
  )
}
